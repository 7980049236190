
import { defineComponent, onMounted, ref, reactive } from "vue";
import { checkWaystar, saveWaystar } from "@/api/waystar.api";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface Data {
  encountersList: any[];
  fileInfos: [];
}

export default defineComponent({
  name: "DevConfig",
  components: {},

  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      encountersList: [],
      fileInfos: [],
    });

    async function check() {
      const res = await checkWaystar();
      data.fileInfos = res;
    }

    async function save() {
      const res = await saveWaystar();
      data.fileInfos = res;
      Swal.fire("Ok!", "Import completed", "success");
    }

    return {
      check,
      save,
      organizationId,
      data,
    };
  },
});
